import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  kitsDisplayer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  selectButtonsArea: {
    display: 'flex',
    alignItems: 'center',
  },
  leftSelectButton: {
    borderTopLeftRadius: 52,
    borderBottomLeftRadius: 52,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRight: "0 !important",
    color: theme.palette.primary.main,
    width: 324,
    height: 62,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      width: 129,
      height: 52,
    }
  },

  leftSelectedButtonInvertedColors: {
    borderTopLeftRadius: 52,
    borderBottomLeftRadius: 52,
    borderColor: 'transparent',
    width: 324,
    height: 62,
    backgroundColor: "#FFF",
    color: theme.palette.secondary.main,
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#FFF",
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("sm")]: {
      width: 129,
      height: 52,
    }
  },

  leftSelectButtonSelected: {
    borderTopLeftRadius: 52,
    borderBottomLeftRadius: 52,
    borderColor: 'transparent',
    width: 324,
    height: 62,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    fontWeight: 700,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down("sm")]: {
      width: 129,
      height: 52,
    }
  },

  leftSelectedButtonInvertedColorsSelected: {
    borderTopLeftRadius: 52,
    borderBottomLeftRadius: 52,
    borderColor: 'transparent',
    width: 324,
    height: 62,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    fontWeight: 700,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down("sm")]: {
      width: 129,
      height: 52,
    }
  },
  rightSelectButton: {
    borderTopRightRadius: 52,
    borderBottomRightRadius: 52,
    border: `1px solid ${theme.palette.primary.main}`,
    borderLeft: "0 !important",
    width: 324,
    height: 62,
    marginLeft: -1,
    color: theme.palette.primary.main,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      width: 129,
      height: 52,
    }
  },
  rightSelectButtonInvertedColors: {
    borderTopRightRadius: 52,
    borderBottomRightRadius: 52,
    borderLeft: "0 !important",
    width: 324,
    height: 62,
    marginLeft: -1,
    color: theme.palette.secondary.main,
    backgroundColor: "#FFF",
    fontWeight: 700,
    '&:hover': {
      backgroundColor: "#FFF",
    },
    [theme.breakpoints.down("sm")]: {
      width: 129,
      height: 52,
    }
  },
  rightSelectButtonSelected: {
    borderTopRightRadius: 52,
    borderBottomRightRadius: 52,
    width: 324,
    height: 62,
    borderColor: 'transparent',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    fontWeight: 700,
    marginLeft: -1,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down("sm")]: {
      width: 129,
      height: 52,
    }
  },
  rightSelectButtonInvertedColorsSelected: {
    borderTopRightRadius: 52,
    borderBottomRightRadius: 52,
    width: 324,
    height: 62,
    borderColor: 'transparent',
    backgroundColor: theme.palette.secondary.main,
    color: "#FFF",
    fontWeight: 700,
    marginLeft: -1,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#FFF",
    },
    [theme.breakpoints.down("sm")]: {
      width: 129,
      height: 52,
    }
  },
  kitInfoArea: {
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'red'
  },
  kitImageArea: {
    display: 'flex',
    marginRight: 64
  },
  kitImage: {
    width: 650,
    height: 600
  },
  kitItemsListArea: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 64,
  },
  planItemContainer: {
    marginLeft: 32,
    marginRight: 32,
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      margin: 0,
    }
  },
  plansCarouselArea: {
    width: '100vw',
    marginTop: 80,
    overflow: 'auto',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    }
  },
  customCarouselButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 122,
    marginTop: 36,
    [theme.breakpoints.down("sm")]: {
      justifyContent: 'center',
      paddingRight: 0,
    }
  },
  customCarouselButton: {
    backgroundColor: '#FFF',
    color: theme.palette.secondary.main,
    marginLeft: 12,
    marginRight: 12,
    "&:hover": {
      backgroundColor: "#FFF"
    }
  }
}));

export default useStyles;