import { Typography } from "@material-ui/core";
import React from "react";
import { PlanItemMonth } from "../../../constants/plans";
import useStyles from "./styles";
import { Check as CheckIcon } from "@material-ui/icons";

interface PlanDisplayerItemProps {
  month: PlanItemMonth;
  index: number;
  isFullPlan: boolean;
}

interface PlanDisplayerItemMonthItem {
  item: string;
}

export function PlanDisplayerItemMonthItem({
  item,
}: PlanDisplayerItemMonthItem) {
  const classes = useStyles();
  return (
    <div className={classes.planDisplayerItemMonthItem}>
      <CheckIcon />
      <Typography variant="h6" className={classes.monthItemText}>
        {item}
      </Typography>
    </div>
  );
}

function PlanDisplayerItem({
  month,
  index,
  isFullPlan = false,
}: PlanDisplayerItemProps) {
  const classes = useStyles();
  return (
    <div
      className={
        !isFullPlan ? classes.planItem : classes.planItemInvertedColors
      }
    >
      <div className={classes.planItemTitleArea}>
        <Typography variant="h3" className={classes.planItemTitleText}>
          {index}º mês
        </Typography>
      </div>
      <div className={classes.planItemsArea}>
        {month.items.map((item: string, index: number) => (
          <PlanDisplayerItemMonthItem item={item} key={index} />
        ))}
      </div>
    </div>
  );
}

export default PlanDisplayerItem;
