import {
  Button,
  Hidden,
  IconButton,
  useTheme,
  withWidth,
} from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@material-ui/icons";
import React, { useState } from "react";
import plans from "../../constants/plans";
import useStyles from "./styles";
import PlanDisplayerItem from "./plan-displayer-item/PlanDisplayerItem";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface PlanDisplayerProps {
  invertedColors?: boolean;
  width: any;
}

function CustomCarouselButtons({ next, previous, goToSlide, ...rest }) {
  const classes = useStyles();

  return (
    <div className={classes.customCarouselButtons}>
      <IconButton
        onClick={() => previous()}
        className={classes.customCarouselButton}
      >
        <ArrowBackIcon />
      </IconButton>
      <IconButton
        onClick={() => next()}
        className={classes.customCarouselButton}
      >
        <ArrowForwardIcon />
      </IconButton>
    </div>
  );
}

function PlansDisplayer({ invertedColors = false, width }: PlanDisplayerProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedPlan, setSelectedPlan] = useState(plans.COMPLETE);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  function onSelectPlan(kit): void {
    setSelectedPlan(kit);
  }

  function getKitButtonClassname(index: number): string {
    if (index % 2 === 0) {
      if (selectedPlan === plans.BASIC) {
        if (invertedColors) {
          return classes.leftSelectedButtonInvertedColorsSelected;
        }
        return classes.leftSelectButtonSelected;
      }
      if (invertedColors) {
        return classes.leftSelectedButtonInvertedColors;
      }
      return classes.leftSelectButton;
    }
    if (selectedPlan === plans.COMPLETE) {
      if (invertedColors) {
        return classes.rightSelectButtonInvertedColorsSelected;
      }
      return classes.rightSelectButtonSelected;
    }
    if (invertedColors) {
      return classes.rightSelectButtonInvertedColors;
    }
    return classes.rightSelectButton;
  }

  function isMobile(width: any): boolean {
    return theme.breakpoints.width(width) <= theme.breakpoints.width("sm");
  }

  return (
    <div className={classes.kitsDisplayer}>
      <div className={classes.selectButtonsArea}>
        {Object.keys(plans).map((plan: string, index: number) => (
          <>
            <Hidden smDown>
              <Button
                variant="outlined"
                className={getKitButtonClassname(index)}
                onClick={() => onSelectPlan(plans[plan])}
                key={index}
              >
                {plans[plan].name}
              </Button>
            </Hidden>
            <Hidden smUp>
              <Button
                variant="outlined"
                className={getKitButtonClassname(index)}
                onClick={() => onSelectPlan(plans[plan])}
                key={index}
              >
                {plans[plan].boxName}
              </Button>
            </Hidden>
          </>
        ))}
      </div>
      <div className={classes.plansCarouselArea}>
        <Carousel
          responsive={responsive}
          arrows={false}
          draggable
          ssr={true}
          centerMode={isMobile(width) ? false : true}
          keyBoardControl={true}
          itemClass={classes.planItemContainer}
          renderButtonGroupOutside={true}
          infinite={true}
          customButtonGroup={<CustomCarouselButtons />}
        >
          {selectedPlan.months.map((month, index: number) => (
            <div className={classes.planItemContainer} key={index}>
              <PlanDisplayerItem
                month={month}
                index={index + 1}
                isFullPlan={selectedPlan === plans.COMPLETE}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default withWidth()(PlansDisplayer);
