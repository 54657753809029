import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  planItem: {
    width: 393,
    height: 290,
    backgroundColor: theme.palette.secondary.main,
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    border: '4px solid #F1A2B1',
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      width: '100%',
    }
  },
  planItemInvertedColors: {
    width: 393,
    height: 290,
    backgroundColor: "#FFF",
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    border: '4px solid #F1A2B1',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      width: '100%',
    }
  },
  planItemTitleArea: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  planItemTitleText: {
    fontFamily: 'Frenchpress, Roboto',
    fontWeight: 400,
  },
  planItemsArea: {
    display: 'flex',
    flexDirection: 'column',
  },
  planDisplayerItemMonthItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 12,
  },
  monthItemText: {
    marginLeft: 12,
    fontFamily: 'Dosis, Roboto',
    fontWeight: 700,

  }
}));

export default useStyles;